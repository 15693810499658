var Robbu = {
    state: false,
    initRobbu: function () {
        chatRobbu.init("6AAC5F6685D6D134", {
            button_icon: "https://www.yamahaservicosfinanceiros.com.br/images/chat.png",
            delay: 0,
            feedback: false,
            logo: "https://www.yamahaservicosfinanceiros.com.br/images/chat.png",
            mobileWidth: 768,
            open: !1,
            theme: "default",
            call: null,
            profile_name: null,
            buttons_bubble: 0,
            user: null,
            wallet_customer_code: null,
            whatsapp: "551120887700",
            whatsapp_message: '',
            whatsapp_position: "left",
            persist_sessions: !0,
        });    
    }
}

export {Robbu} 